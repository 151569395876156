export const copyObjectWithDescriptors = <T extends object>(obj: T): T => {
  return Object.create(
    Object.getPrototypeOf(obj),
    Object.getOwnPropertyDescriptors(obj),
  )
}

export const mergeWithDescriptors = <T extends object>(
  target: T,
  ...sources: object[]
): T => {
  // Get property descriptors from the target object
  const targetDescriptors = Object.getOwnPropertyDescriptors(target)

  // Merge sources into the target object
  sources.forEach((source) => {
    const sourceDescriptors = Object.getOwnPropertyDescriptors(source)

    // Add or overwrite properties in targetDescriptors
    Object.keys(sourceDescriptors).forEach((key) => {
      ;(targetDescriptors[key] as any) = sourceDescriptors[key]
    })
  })

  // Create a new object with the merged descriptors and the original prototype
  return Object.create(Object.getPrototypeOf(target), targetDescriptors)
}
